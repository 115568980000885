<template>
  <div
      class="app-content content chat-application"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"></div>
    <div class="content-area-wrapper">
      <chat-left-sidebar
          :chats-contacts="chatsContacts"
          :contacts="contacts"
          :active-chat-contact-id="activeContact.id"
          :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
          :profile-user-data="profileUserData"
          :profile-user-minimal-data="profileUserDataMinimal"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @show-user-profile="showUserProfileSidebar"
          @open-chat="openChatOfContact"
      />
      <div class="content-right">
        <div class="content-wrapper">
          <div class="content-body">
            <div style="height: inherit">
              <div
                  class="body-content-overlay"
                  :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
                  @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
              />
              <section class="chat-app-window">
                <div
                    v-if="!activeChat"
                    class="start-chat-area"
                >
                  <div class="mb-1 start-chat-icon">
                    <feather-icon icon="MessageSquareIcon" size="56"/>
                  </div>
                  <h4 class="sidebar-toggle start-chat-text" @click="startConversation">Начать диалог</h4>
                </div>
                <div v-else class="active-chat">
                  <div class="chat-navbar">
                    <header class="chat-header">
                      <div class="d-flex align-items-center">
                        <div class="sidebar-toggle d-block d-lg-none mr-1">
                          <feather-icon
                              icon="MenuIcon"
                              class="cursor-pointer"
                              size="21"
                              @click="mqShallShowLeftSidebar = true"
                          />
                        </div>
                        <div class="b-avatar badge-light-primary rounded-circle" style="width: 32px; height: 32px;">
                          <span class="b-avatar-text" style="font-size: calc(12.8px);">
                            <span>{{ avatarText(activeContact.fullName) }}</span>
                          </span>
                        </div>
                        <h6 class="mb-0">{{ activeContact.fullName }}</h6>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="dropdown">
                          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                            <template #button-content>
                              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body"/>
                            </template>
                            <b-dropdown-item>Отключить уведомления</b-dropdown-item>
                            <b-dropdown-item>Очистить чат</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </header>
                  </div>
                  <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings"
                                         class="user-chats scroll-area">
                    <div class="chats">
                      <div
                          v-for="message in activeChat"
                          :key="message.id"
                          class="chat"
                          :class="{'chat-left': message.from_user_id !== 255}"
                      >
                        <div class="chat-avatar">
                          <b-avatar
                              size="36"
                              class="avatar-border-2 box-shadow-1"
                              variant="transparent"
                          />
                        </div>
                        <div class="chat-body">
                          <div class="chat-content">
                            <p v-if="message.type === 'message'">{{ message.message }}</p>
                            <div class="row" v-else >
                              <div v-for="file in message.files" :key="file" class="col mt-1">
                                <img :src="`/chat/files/${file}`"  class="w-75" alt="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vue-perfect-scrollbar>
                  <b-form class="chat-app-form" @submit.prevent="sendMessage">
                    <b-form-select
                        v-model="form.type"
                        style="width: auto"
                        :options="types"
                    />
                    <div
                        v-if="form.type === 'message'"
                        role="group"
                        class="input-group input-group-merge form-send-message mr-1"
                    >
                      <input v-model="form.message" placeholder="Введите ваше сообщение" class="form-control">
                    </div>
                    <b-form-file
                        v-else
                        v-model="form.files"
                        placeholder="Выберите файлы или перетяните сюда..."
                        drop-placeholder="Перетяните файлы сюда..."
                        multiple
                    />
                    <button type="submit" class="btn btn-primary">Отправить</button>
                  </b-form>
                </div>
              </section>
              <chat-active-chat-content-details-sidedbar
                  :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
                  :contact="activeContact || {}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, nextTick } from '@vue/composition-api';
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BFormSelect, BFormFile,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { $themeBreakpoints } from '@themeConfig';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import { avatarText } from '@core/utils/filter';
import store from '@/store';
import ChatLeftSidebar from './ChatLeftSidebar.vue';
import chatStoreModule from './chatStoreModule';
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue';
import useChat from './useChat';

export default {
  name: 'Chat',
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    BFormSelect,
    BFormFile,
  },
  data() {
    return {
      contacts: [],
      chatsContacts: [],
      activeChat: null,
      types: [
        { text: 'Текст', value: 'message' },
        { text: 'Файл', value: 'file' },
      ],
      activeContact: {},
      form: {
        message: '',
        type: 'message',
        files: [],
      },
    };
  },
  async mounted() {
    await this.getContacts();
  },
  methods: {
    async getContacts() {
      this.contacts = (await this.$api.chat.contacts()).data;
      this.chatsContacts = (await this.$api.chat.chats()).data;
    },
    async openChatOfContact(userId) {
      this.activeChat = this.chatsContacts.find((c) => c.id === userId).dialogs.all;
      this.activeContact = { ...this.contacts.find((c) => c.id === userId) };

      nextTick(() => {
        this.scrollToBottomInChatLog();
      });
      await this.$api.chat.setRead();
    },
    async sendMessage() {
      if (!this.form.message && !this.form.files.length) return;

      const formData = new FormData();
      formData.append('message', this.form.message);
      formData.append('type', this.form.type);
      this.form.files.forEach((file) => {
        formData.append('files[]', file);
      });

      try {
        const message = await this.$api.chat.sendMessage(this.activeContact.id, formData);
        this.activeChat.push(message.data);
        this.message = null;
      } catch (e) {
        // console.error(e);
      }
      nextTick(() => {
        this.scrollToBottomInChatLog();
      });
    },
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat';

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });

    const { resolveAvatarBadgeVariant } = useChat();

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([]);
    const contacts = ref([]);

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/fetchChatsAndContacts')
          .then((response) => {
            chatsContacts.value = response.data.chatsContacts;
            contacts.value = response.data.contacts;
            // eslint-disable-next-line no-use-before-define
            profileUserDataMinimal.value = response.data.profileUser;
          });
    };

    fetchChatAndContacts();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({});
    const openChatOfContact = (userId) => {
      // Reset send message input value

      store.dispatch('app-chat/getChat', { userId })
          .then((response) => {
            activeChat.value = response.data;

            // Set unseenMsgs to 0
            const contact = chatsContacts.value.find((c) => c.id === userId);
            if (contact) contact.dialogs.unseenMsgs = 0;

            // Scroll to bottom
            nextTick(() => {
              scrollToBottomInChatLog();
            });
          });

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false;
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
          .then((response) => {
            profileUserData.value = response.data;
            shallShowUserProfileSidebar.value = true;
          });
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Single Chat
      refChatLogPS,
      scrollToBottomInChatLog,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,
      avatarText,
      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
