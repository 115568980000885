<template>
  <div class="user-profile-sidebar" :class="{'show': shallShowActiveChatContactSidebar}" >
    <header v-if="contact" class="user-profile-header" >
      <span class="close-icon">
        <feather-icon icon="XIcon" @click="$emit('update:shall-show-active-chat-contact-sidebar', false)" />
      </span>
      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar size="70" :src="contact.avatar && require(`@/assets/images/avatars/${contact.avatar}`)" />
          <span class="avatar-status-xl" :class="`avatar-status-${contact.status}`" />
        </div>
        <h4 class="chat-user-name">{{ contact.fullName }}</h4>
        <span class="user-post text-capitalize">{{ contact.role }}</span>
      </div>
    </header>
    <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150 }" class="user-profile-sidebar-area scroll-area" >
      <h6 class="section-label mb-1">Информация</h6>
      <p>{{ contact.about }}</p>
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">Контакты</h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <feather-icon icon="MailIcon" size="16" class="mr-75" />
            <span class="align-middle">info@brandstock.online</span>
          </li>
          <li>
            <feather-icon icon="ClockIcon" size="16" class="mr-75" />
            <span class="align-middle">Пн - Пт 10.00 - 16.00</span>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BAvatar } from 'bootstrap-vue';

export default {
  components: { BAvatar, VuePerfectScrollbar },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
