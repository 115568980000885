<template>
  <li v-on="$listeners">
    <b-avatar
      size="42"
      :src="require(`@/assets/images/avatars/${user.avatar}`)"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="transparent"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">{{ user.fullName }}</h5>
      <p class="card-text text-truncate">
        {{ isChatContact && user.dialogs.lastMessage ? user.dialogs.lastMessage.message : user.about }}
      </p>
    </div>
    <div v-if="isChatContact" class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time" v-if="user.dialogs.lastMessage">
        {{ formatDateToMonthShort(user.dialogs.lastMessage.created_at, { hour: 'numeric', minute: 'numeric' }) }}
      </small>
      <b-badge v-if="user.dialogs.newQty" pill variant="primary" class="float-right">{{ user.dialogs.newQty }}</b-badge>
    </div>
  </li>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import useChat from './useChat';

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    user: { type: Object, required: true },
    isChatContact: { type: Boolean, default: false },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style>

</style>
